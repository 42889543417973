// @ts-nocheck
import { massaModelsData } from './gltfmodels.prod';
import { featureServices } from './feature-services';
import * as configcat from 'configcat-js';

export const environment = {
    production: true,
    hmr: false,
    azureApiKey: 'pj37PZ3H47u76prChBNUoN9j8nKXhqwZtbYfuf0H0pg',
    authClientId: '1b114da5-f04d-4f6b-9c62-7205a8fdcae6',
    authAuthority: 'https://login.microsoftonline.com/0ef544fb-3d5c-4c60-932e-735bcef00405/',
    authScopes: [],
    azureBaseUrl: 'https://vwsearch.volkerwessels.app/api',
    arcgisApiKey: 'AAPK2fe9be6fd2d14cb8afae28445dfd9ea9hyX0X5tl_JPhSjT2IS-jaOixbaTvKyKUNXcDx0xzswA8qSc5ISl4kaxsb05rhAsG',
    arcgisBasemapStyleUrl: 'https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles',
    trimbleApiBaseUrl: 'https://app21.connect.trimble.com/tc/api/2.0',
    trimbleOAuthBaseUrl: 'https://id.trimble.com',
    trimbleOAuthClientId: '191edd45-2c7a-4027-bdfd-7a43a07d8d97',
    trimbleOAuthRedirectUri: 'https://vwsearch.volkerwessels.app/trimble/oauth',
    trimbleOAuthScope: 'openid%20VWMaps',
    searchInstrumentationKey: null,
    searchServiceName: null,
    vectorTilesBlobUrl: 'https://bvgowesap3ddpvectortiles.blob.core.windows.net',
    geoserverUrl: 'https://bvgo-we-as-p-3ddp-geoserver.azurewebsites.net/geoserver',
    mapproxyBaseUrl: 'https://vwsearch.volkerwessels.app/mapproxy',
    massamodels: massaModelsData,
    prsBaseUrl: 'https://prs.volkerwessels.app/',
    baseUrl: 'https://vwsearch.volkerwessels.app/',
    detectAdblock: true,
    projectenPortaalBaseUrl: 'https://projectenportaal.volkerwessels.app/',
    configCatKey: 'configcat-sdk-1/cqTbCOChCEONKYa815sJNg/YWNp1gW-SE2C34e9B04Ykw',
    configCatLogLevel: configcat.LogLevel.Off,
    featureServices
};
